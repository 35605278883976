<i18n>
{
  "ru": {
    "title": "Изменение группы"
  },
  "he": {
    "title": "שינוי קבוצה"
  }
}
</i18n>

<template>
  <div>
    <h1 class="text-h4 mb-2">{{ $t('title') }}</h1>

    <v-snackbar
      v-model="snackbar.show"
      :timeout="1500"
      :color="snackbar.color"
      centered
    >
      <div class="text-center" v-html="snackbar.text"></div>
    </v-snackbar>

    <v-form v-model="valid">
      <v-row class="mb-5">
        <template v-for="(item, index) in propsInfo">
          <v-col
            cols="6"
            :key="`prop-item-info-${index}`"
          >
            <template v-if="item.type === 'switch'">
              <v-switch
                v-model="item.value"
                :label="item.name"
                :disabled="item.disabled"
                color="teal"
                :false-value="item.falseValue"
                :true-value="item.trueValue"
              ></v-switch>
            </template>

            <template v-if="item.type === 'text'">
              <v-text-field
                v-model="item.value"
                :label="item.name"
                :disabled="item.disabled"
                :required="item.required"
                :rules="item.rules"
                color="teal"
              ></v-text-field>
            </template>

            <template v-if="item.type === 'number'">
              <v-text-field
                v-model.number="item.value"
                :label="item.name"
                :disabled="item.disabled"
                :required="item.required"
                :rules="item.rules"
                color="teal"
              ></v-text-field>
            </template>
          </v-col>
        </template>
      </v-row>

      <v-row class="mt-5">
        <v-col
          cols="auto"
        >
          <v-btn
            color="teal"
            dark
            :loading="btnLoading"
            @click="save()"
          >{{ $t('btn.save') }}</v-btn>
        </v-col>

        <v-col
          cols="auto"
        >
          <v-btn
            color="grey lighten-5"
            to="/groups-goods/"
          >{{ $t('btn.cancel') }}</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
export default {
  name: 'GoodEdit',
  data: (vm) => ({
    valid: false,
    btnLoading: false,
    snackbar: {},
    props: [
      {
        name: vm.$t('props.UF_NAME'),
        code: 'UF_NAME',
        type: 'text',
        disabled: false,
        required: true,
        rules: [
          (v) => !!v || vm.$t('required'),
        ],
        value: '',
        group: 'info',
      },
      {
        name: vm.$t('props.UF_SORT'),
        code: 'UF_SORT',
        type: 'number',
        disabled: false,
        required: true,
        rules: [
          (v) => !!v || vm.$t('required'),
        ],
        value: 500,
        group: 'info',
      },
      {
        name: vm.$t('props.UF_ACTIVE'),
        code: 'UF_ACTIVE',
        type: 'switch',
        disabled: false,
        required: false,
        rules: [],
        value: '1',
        group: 'info',
        falseValue: '0',
        trueValue: '1',
      },
    ],
  }),
  computed: {
    id() {
      return +this.$route.params.id;
    },
    propsInfo() {
      return this.props.filter((item) => item.group === 'info');
    },
  },
  methods: {
    getProps() {
      this.$store.dispatch('getGroupGoods', this.id).then((response) => {
        const { result } = response.data.data;

        if (result) {
          this.props = this.props.map((item) => {
            const prop = item;

            prop.value = result[prop.code];

            return prop;
          });
        }
      });
    },
    save() {
      if (this.valid) {
        this.btnLoading = true;
        const params = new FormData();
        const props = {};

        this.props.forEach((prop) => {
          props[prop.code] = prop.value;
        });

        params.append('id', this.id);
        params.append('params', JSON.stringify(props));

        this.$store.dispatch('updateGroupGoods', params).then((response) => {
          const { success } = response.data.data;

          this.btnLoading = false;
          this.snackbar = {
            show: true,
            color: (success) ? 'green darken-3' : 'red darken-3',
            text: response.data.data.message,
          };
        });
      } else {
        this.snackbar = {
          show: true,
          color: 'red darken-3',
          text: this.$t('errorEmptyProps'),
        };
      }
    },
  },
  created() {
    this.getProps();
  },
};
</script>
